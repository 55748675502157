@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
.lds-dual-ring {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=Spinner.css.map */
